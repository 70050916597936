import React from 'react';
import { graphql } from 'gatsby';
import PartnersSection from 'components/PartnersSection';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import ArticleIndexSection from '../../components/ArticleIndexSection';

const BlogIndex = ({ data }) => {
  const {
    allDatoCmsArticle,
    datoCmsGlobal,
    datoCmsMainMenu,
    datoCmsGettingInvolvedPage,
    datoCmsHomepage,
    allDatoCmsPartner,
    allDatoCmsCategory,
    datoCmsBlogArticlesList,
  } = data;

  return (
    <Layout
      globalData={datoCmsGlobal}
      mainMenuData={datoCmsMainMenu}
      engagementKinds={datoCmsGettingInvolvedPage?.involvementKind}
    >
      <Seo
        title={datoCmsBlogArticlesList?.metaTags?.title}
        description={datoCmsBlogArticlesList?.metaTags?.description}
      />
      <ArticleIndexSection
        data={allDatoCmsArticle}
        categoryData={allDatoCmsCategory}
        variant="topSection"
      />
      <PartnersSection
        data={datoCmsHomepage}
        partners={allDatoCmsPartner?.edges ?? []}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    datoCmsBlogArticlesList {
      metaTags {
        description
        title
      }
    }
    allDatoCmsArticle(sort: { fields: meta___createdAt, order: DESC }) {
      nodes {
        id: originalId
        slug
        author {
          name
          description
          avatar {
            url
            alt
          }
        }
        meta {
          createdAt(formatString: "DD MMM YYYY", locale: "fr")
        }
        title
        description
        picture {
          url
          alt
          ... on DatoCmsFileField {
            gatsbyImageData
          }
        }
        category {
          name
          slug
        }

        content {
          blocks {
            __typename
            ... on DatoCmsImage {
              id: originalId
              image {
                gatsbyImageData
                url
                title
                isImage
              }
            }
            __typename
            ... on DatoCmsYoutube {
              id: originalId
              link {
                title
                url
                providerUid
                provider
                height
                width
              }
            }
          }
          value
          links
        }
      }
    }

    datoCmsHomepage {
      partnersOvertitle
      partnersTitle
    }

    datoCmsGlobal {
      facebookLink
      instagramLink
      linkedinLink
      mailContact
      twitterLink
      footerText
      terms
    }

    allDatoCmsPartner {
      edges {
        node {
          id
          name
          logo {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
        }
      }
    }

    datoCmsGettingInvolvedPage {
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }

    datoCmsMainMenu {
      blogLink
      galleryLink
      homepageLink
      involvmentLink
    }

    allDatoCmsCategory {
      nodes {
        name
        id: originalId
        slug
      }
    }
  }
`;

export default BlogIndex;
