import React from 'react';
import styled from 'styled-components';
import { ButtonReset, Stack } from '@tymate/margaret';
import { Tag } from 'ui/base';

const Wrapper = styled(Stack).attrs({})`
  max-width: 75%;
  text-align: center;
`;

const Content = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
  gutterSize: { default: 0.5, tablet: 2 },
})`
  width: 100%;
  ${({ theme }) => theme.media.tablet`
    display: inline-block;
  `};
`;

const CategorySelector = ({ data, activeCategory, onSelectCategory }) => {
  const categoryAll = [{ id: 0, name: 'Tous', slug: 'all' }];
  const categoryList = categoryAll.concat(data?.nodes) ?? [];

  const handleCategorySelection = slug => {
    onSelectCategory(slug);
  };

  return (
    <Wrapper>
      <Content>
        {categoryList.map((category, index) => (
          <ButtonReset
            key={index}
            onClick={() => {
              handleCategorySelection(category.slug);
            }}
          >
            <Tag isNotSelected={activeCategory !== category.slug}>
              {category.name}
            </Tag>
          </ButtonReset>
        ))}
      </Content>
    </Wrapper>
  );
};

export default CategorySelector;
