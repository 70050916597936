import React, { useState } from 'react';
import { Container, Stack, ButtonReset } from '@tymate/margaret';
import { Title, Section } from 'ui';
import ArticleIndexItem from './ArticleIndexItem';
import { navigate } from 'gatsby';
import CategorySelector from './CategorySelector';
import styled from 'styled-components';

const handleClick = articleData => navigate(`/blog/${articleData.slug}`);

const EmptyState = styled(Title)`
  font-size: 1.2rem;
  padding-top: ${({ theme }) => theme.spacing(2)};
  height: 180px;
`;

const Wrapper = styled(Stack).attrs({
  direction: 'column',
  gap: { default: 2 },
})`
  ${({ theme }) => theme.media.tablet`
    max-width: 600px;
  `}

  ${({ theme }) => theme.media.desktop`
    max-width: 800px;
  `}
`;

const ArticleIndexSection = ({ data, categoryData, variant }) => {
  const allCategoriesSelection = data?.nodes || [];
  const [activeCategorySlug, setActiveCategorySlug] = useState('all');
  const [currentSelection, setCurrentSelection] = useState(
    allCategoriesSelection,
  );

  const handleCategorySelection = slug => {
    setActiveCategorySlug(slug);
    const filterResults = allCategoriesSelection.filter(article => {
      return article.category.slug === slug;
    });
    const newSelection =
      slug === 'all' ? allCategoriesSelection : filterResults;
    setCurrentSelection(newSelection);
  };

  return (
    <Section variant={variant}>
      <Container paddingHorizontal={1}>
        <Stack direction="column" gap={2} alignX="center">
          <Title>Blog</Title>
          <CategorySelector
            data={categoryData}
            onSelectCategory={handleCategorySelection}
            activeCategory={activeCategorySlug}
          />
          <Wrapper>
            {currentSelection.length === 0 && (
              <EmptyState>
                Il n'y a pas d'article dans cette catégorie.
              </EmptyState>
            )}

            {currentSelection.map((articleData, index) => (
              <ButtonReset
                key={index}
                onClick={() => {
                  handleClick(articleData);
                }}
              >
                <ArticleIndexItem data={articleData} />
              </ButtonReset>
            ))}
          </Wrapper>
        </Stack>
      </Container>
    </Section>
  );
};

export default ArticleIndexSection;
