import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { GatsbyImage as RawGatsbyImage, getImage } from 'gatsby-plugin-image';
import { Tag } from '../ui/base';
import { Title } from 'ui';

const Content = styled(Stack).attrs({
  direction: 'row',
  alignX: 'center',
  gutterSize: 1,
})`
  border-radius: 2px;
  height: 180px;

  padding: ${({ theme }) => theme.spacing()};
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  height: 100%;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  }
  transition: box-shadow 0.2s ease-in-out;
`;

const ArticleTitle = styled(Title)`
  font-size: 1.2rem;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ArticleOverTitle = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};
  text-align: 'flex-start';
  color: ${({ theme }) => theme.textSecondary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ArticleDate = styled.div`
  font-size: 14px;
  line-height: 1em;
  max-height: 1em;
  overflow: hidden;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textSecondary};
`;

const TextStack = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.375,
  flex: 2,
})`
  background-color: transparent;
  overflow: hidden;
`;

const GatsbyImage = styled(RawGatsbyImage)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 140px;
  min-width: 130px;
  border-radius: 20px;
  overflow: hidden;

  ${({ theme }) =>
    theme.media.tablet`
      height: 180px;
      min-width: 260px;
  `}
`;

const ArticleIndexItem = data => {
  const picture = data.data?.picture;
  const category = data.data?.category.name;
  const date = data.data?.meta?.createdAt;
  const title = data.data?.title;
  const description = data.data?.description;

  return (
    <Content>
      <GatsbyImage
        image={getImage(picture)}
        alt={picture?.alt || picture?.title || ''}
        objectFit="cover"
      />
      <TextStack>
        <Tag>{category}</Tag>
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleOverTitle>{description}</ArticleOverTitle>
        <ArticleDate>{date}</ArticleDate>
      </TextStack>
    </Content>
  );
};

export default ArticleIndexItem;
